import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const lechampduvillage = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Le champ du village" />
    <h3 className='underline-title'>Le champ du village</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        À travers le monde de la magie enfantine<br />
        Le reflet du champ féerique hante ma mémoire croulante<br />
        C'était l'immensité verdoyante du silence<br />
        Que seuls violaient les tisserins gazouilleurs<br />
      </p>
      <p>
        Ce champ me plaisait<br />
        Il me charmait par son aspect d'asymétrie villageoise<br />
        Le blanc immaculé des touffes cotonneuses<br />
        Donnait du relief au vet foncé du prince "ngunza"<br />
      </p>
      <p>
        Les feuilles grimpantes d'ignames terriennes<br />
        Enlançaient les virils mais qu'elles n'étouffaient jamais<br />
        Les bananiers déployaient leurs amples "parasolpluies"<br />
        Pour la protection des plantes naines<br />
      </p>
      <p>
        Ce champ me plaisait<br />
        Par ses troncs énormes victimes du défrichement<br />
        Qui me servaient de chaires det de chevaux<br />
        Ces troncs me connurent jockey à califourchon<br />
        Et rustique babillard espiègle<br />
      </p>
      <p>
        Ce champ me plaisait<br />
        Par la présence de mes parents tout-puissants<br />
        Qu'il tenait cois et dissimulés<br />
        Je les savais pourtant vigilants<br />
      </p>
      <p>
        Ce champ me plaisait<br />
        Il me rendait souverain et libre<br />
        En occupant mes chers parents<br />
        Qui ne se souciaient de mes caprices<br />
      </p>
      <p>
        Ce champ me plaisait encore<br />
        Par ses collines coiffées<br />
        Hérissées de cannes à sucre hautaines<br />
        Qui par-dessus les épaules<br />
        Toisaient le peuple herbacé du vallon<br />
      </p>
      <p>
        Ce champ me plaisait<br />
        Il me plait encore en vision<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">ngunza: feuilles de manioc</p>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default lechampduvillage
